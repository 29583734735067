import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../../components/RawHTML';
import Container from '../../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFViewCtrl.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrl.visiblePages</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrl.currentPage</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrl.zoom</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrl.pageViewMode</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrl.pageRefViewMode</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrl.pagePresentationMode</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrl.rotation</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrl.colorPostProcessMode</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrl.selectionBeginPage</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrl.selectionEndPage</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrl.canvasWidth</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrl.canvasHeight</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrl.pageCount</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotationToolbar.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTAnnotationToolbar</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>PTEditToolbarDelegate, PTFreeHandCreateDelegate</td></tr>
<tr><th>To</th><td>PTEditToolbarDelegate, PTFreeHandCreateDelegate, PTOverridable</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotationViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotationViewController.readonly</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTAnnotationViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td><em>none</em></td></tr>
<tr><th>To</th><td>PTOverridable</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> PTAnnotManagerDelegate</div>
<div class="difference"><span class="status removed">Removed</span> -[PTAnnotManagerDelegate localChangeWithAction:command:json:]</div>
<div class="difference"><span class="status removed">Removed</span> PTAnnotManager</div>
<div class="difference"><span class="status removed">Removed</span> PTAnnotManager.delegate</div>
<div class="difference"><span class="status removed">Removed</span> -[PTAnnotManager initWithExternalManager:toolManager:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTAnnotManager GetLastXFDF]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTAnnotManager MergeXFDF:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTAnnotManager takeSnapshot:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotStyleViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTAnnotStyleViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>PTAnnotStyleDelegate</td></tr>
<tr><th>To</th><td>PTAnnotStyleDelegate, PTOverridable</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTBookmarkViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTBookmarkViewController.readonly</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTBookmarkViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td><em>none</em></td></tr>
<tr><th>To</th><td>PTOverridable</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCollaborationAnnotation.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTCollaborationAnnotation</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationAnnotation.annotationID</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationAnnotation.userID</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationAnnotation.userName</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationAnnotation.xfdf</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationAnnotation.parent</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationAnnotation.documentID</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCollaborationDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTCollaborationDocumentViewController</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationDocumentViewController.service</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationDocumentViewController initWithCollaborationService:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCollaborationManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTCollaborationServerCommunication</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationServerCommunication.collaborationManager</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationServerCommunication localAnnotationAdded:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationServerCommunication localAnnotationModified:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationServerCommunication localAnnotationRemoved:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationServerCommunication documentLoaded]</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationServerCommunication userID]</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationManager</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationManager initWithExternalManager:toolManager:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationManager remoteAnnotationAdded:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationManager remoteAnnotationModified:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationManager remoteAnnotationRemoved:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationManager registerServerCommunicationComponent:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCollaborationManager takeSnapshot:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDigSigViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTDigSigViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>UIImagePickerControllerDelegate, UINavigationControllerDelegate</td></tr>
<tr><th>To</th><td>PTOverridable, UIImagePickerControllerDelegate, UINavigationControllerDelegate</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.pdfLayerListHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewControllerDidOpenDocumentNotification</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTDocumentViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>PTAnnotationToolbarDelegate, PTAnnotationViewControllerDelegate, PTBookmarkViewControllerDelegate, PTCoordinatedDocumentDelegate, PTOutlineViewControllerDelegate, PTOverridable, PTPDFViewCtrlDelegate, PTReflowViewControllerDelegate, PTSettingsViewControllerDelegate, PTTextSearchViewControllerDelegate, PTThumbnailSliderViewDelegate, PTToolManagerDelegate</td></tr>
<tr><th>To</th><td>PTAnnotationToolbarDelegate, PTAnnotationViewControllerDelegate, PTBookmarkViewControllerDelegate, PTCoordinatedDocumentDelegate, PTOutlineViewControllerDelegate, PTOverridable, PTPDFLayerViewControllerDelegate, PTPDFViewCtrlDelegate, PTReflowViewControllerDelegate, PTSettingsViewControllerDelegate, PTTextSearchViewControllerDelegate, PTThumbnailSliderViewDelegate, PTToolManagerDelegate</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTEditToolbar.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTEditToolbar</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td><em>none</em></td></tr>
<tr><th>To</th><td>PTOverridable</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTFloatingSigViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTFloatingSigViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td><em>none</em></td></tr>
<tr><th>To</th><td>PTOverridable</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTImageStampAnnotationOptions.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTImageStampAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTImageStampAnnotationOptions.rotationEnabled</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTNavigationListsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTNavigationListsViewControllerVisibility</div>
<div class="difference"><span class="status added">Added</span> PTNavigationListsViewControllerVisibilityAutomatic</div>
<div class="difference"><span class="status added">Added</span> PTNavigationListsViewControllerVisibilityNeverHidden</div>
<div class="difference"><span class="status added">Added</span> PTNavigationListsViewControllerVisibilityAlwaysHidden</div>
<div class="difference"><span class="status added">Added</span> PTNavigationListsViewController.pdfLayerViewController</div>
<div class="difference"><span class="status added">Added</span> PTNavigationListsViewController.pdfLayerViewControllerVisibility</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTNavigationListsViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td><em>none</em></td></tr>
<tr><th>To</th><td>PTOverridable</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTOutlineViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTOutlineViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td><em>none</em></td></tr>
<tr><th>To</th><td>PTOverridable</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTOverridable.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> +[PTOverridable allocOverridden]</div>
<table>
<tr><th></th><th>Optional</th></tr>
<tr><th>From</th><td>Required</td></tr>
<tr><th>To</th><td>Optional</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTPageIndicatorViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTPageIndicatorViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td><em>none</em></td></tr>
<tr><th>To</th><td>PTOverridable</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTPDFLayerViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTLayerInfo</div>
<div class="difference"><span class="status added">Added</span> PTLayerInfo.group</div>
<div class="difference"><span class="status added">Added</span> PTLayerInfo.state</div>
<div class="difference"><span class="status added">Added</span> PTPDFLayerViewControllerDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFLayerViewControllerDelegate pdfLayerViewControllerDidCancel:]</div>
<div class="difference"><span class="status added">Added</span> PTPDFLayerViewController</div>
<div class="difference"><span class="status added">Added</span> PTPDFLayerViewController.layers</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFLayerViewController initWithPDFViewCtrl:]</div>
<div class="difference"><span class="status added">Added</span> PTPDFLayerViewController.delegate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTReflowViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTReflowViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td><em>none</em></td></tr>
<tr><th>To</th><td>PTOverridable</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSettingsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTSettingsViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td><em>none</em></td></tr>
<tr><th>To</th><td>PTOverridable</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTextSearchViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTTextSearchViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>UISearchBarDelegate, UISearchControllerDelegate, UITableViewDataSource, UITableViewDelegate, UIToolbarDelegate</td></tr>
<tr><th>To</th><td>PTOverridable</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTThumbnailSliderViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTThumbnailSliderViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td><em>none</em></td></tr>
<tr><th>To</th><td>PTOverridable</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTThumbnailsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTThumbnailsViewController.collectionView</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTThumbnailsViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>UICollectionViewDataSource, UICollectionViewDelegateFlowLayout</td></tr>
<tr><th>To</th><td>PTOverridable, UICollectionViewDataSource, UICollectionViewDelegateFlowLayout</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTToolManager enableAnnotManager:]</div>
<div class="difference"><span class="status removed">Removed</span> PTToolManager.annotManager</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTToolManager enableCollaborationManager:]</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.collaborationManager</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTToolManager.imageStampAnnotationOptions</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, copy, nonnull) PTAnnotationOptions *imageStampAnnotationOptions</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, copy, nonnull) PTImageStampAnnotationOptions *imageStampAnnotationOptions</td></tr>
</table>
<br>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}